//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import find from 'lodash/find'

export default {
  middleware: 'guest',
  async asyncData({ query, store, redirect }) {
    const { invitationCode } = query
    if (invitationCode) {
      try {
        const detailInvitation = await store.dispatch(
          'staff/invitationLink/verifyInvitation',
          invitationCode
        )
        return {
          detailInvitation
        }
      } catch (error) {
        redirect('/login')
      }
    }
  },
  data() {
    const { email } = this.$route.query
    const dataNa = {
      email: '',
      emailDisabled: false,
      password: '',
      detailInvitation: null,
      alert: null,
      year: new Date().getFullYear()
    }
    if (email) {
      dataNa.email = email
      dataNa.emailDisabled = true
    }
    return dataNa
  },
  head() {
    return {
      title: 'Login | Scalev'
    }
  },
  computed: {
    ...mapGetters('business', ['getBusiness']),
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    hasInvitationDetail() {
      return this.$route.query.invitationCode && !!this.detailInvitation
    },
    registerLink() {
      if (this.hasInvitationDetail) {
        return `/register?invitationCode=${this.$route.query.invitationCode}`
      }
      return '/register'
    }
  },
  mounted() {
    if (this.$route.query.new_email) {
      this.showAlert({ message: 'Email kamu berhasil diperbaharui' }, 'success')
    } else if (this.$route.query.fail_new_email) {
      this.showAlert(
        { message: 'Link verifikasi email baru tidak berlaku' },
        'error'
      )
    }
  },
  methods: {
    showAlert(d, type) {
      if (d && type) {
        const msg = d.message.replace('__all__', '')
        this.alert = {
          title: d.title,
          message: msg,
          type
        }
      }
    },
    toPathWithoutQ() {
      this.detailInvitation = null
      this.$router.push(this.$route.path)
    },
    async login() {
      this.$nuxt.$loading.start(true)
      const { invitationCode, email, businessUserId } = this.$route.query
      try {
        const res = await this.$store.dispatch('user/login', {
          email: this.email.trim(),
          password: this.password
        })
        this.$cookies.set('at', res.access, {
          path: '/',
          maxAge: 1000 * 3600 * 24 * 30
        })
        this.$cookies.set('rt', res.refresh, {
          path: '/',
          maxAge: 1000 * 3600 * 24 * 30
        })
        if (invitationCode) {
          await this.$store.dispatch('staff/joinRequest/storeData', {
            email: this.email.trim(),
            invitationCode
          })
          await this.$auth.fetchUser()
        }
      } catch (error) {
        if (this.$auth.user) {
          await this.$auth.logout()
          await this.$router.push(`/login?invitationCode=${invitationCode}`)
        }
        this.showAlert({ message: this.$errorHandlerMessage(error) }, 'error')
        this.$nuxt.$loading.finish()
        return
      }

      try {
        if (email && businessUserId) {
          const findCurrentBusiness = find(
            this.getBusiness,
            (bus) => bus.idMember === Number(businessUserId)
          )
          await this.$store.dispatch(
            'business/changeBusiness',
            findCurrentBusiness.id
          )
          await this.$auth.fetchUser()
          await this.$store.dispatch(
            'common/checkInvitation',
            this.$auth.user.currentBusiness
          )
          // await this.$store.dispatch(
          //   'common/checkBusinessPhone',
          //   this.$auth.user.currentBusiness
          // )
        }
        this.$router.push('/dashboard')
      } catch (error) {
        this.showAlert({ message: this.$errorHandlerMessage(error) }, 'error')
        this.$nuxt.$loading.finish()
      }
    }
  }
}
